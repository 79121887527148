import { Box } from '@chakra-ui/core'
import React from 'react'
import styled from 'styled-components'

import desktopLogoImageOfSmilux from '../../../../../assets/images/desktopLogoSmilux.svg'
import desktopLogoImageOfSOV from '../../../../../assets/images/desktopLogoSOV.svg'
import desktopHeroImageOfSmilux from '../../../../../assets/static/service/desktopHeroSmilux.png'
import desktopHeroImageOfSOV from '../../../../../assets/static/service/desktopHeroSOV.png'
import { GridLayout } from '../../../../../components/Grid'
import { neutral } from '../color'
import DesktopComparisonHeaderItem from './DesktopComparisonHeaderItem'
import DesktopComparisonRowItem from './DesktopComparisonRowItem'
import { ComparisonInfo } from '..'

const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
`

const HeaderRow = styled.div``

const Versus = styled.div`
  color: ${neutral};
  font-size: 64px;
  line-height: 64px;
  letter-spacing: 1.1px;
  display: flex;
  justify-content: center;
`

interface DesktopProps {
  comparisonInfoList: ComparisonInfo[]
}

const Desktop = (props: DesktopProps) => {
  const { comparisonInfoList } = props

  return (
    <Wrapper>
      <HeaderRow>
        <GridLayout columnGap={{ base: '16px', md: '24px' }} rowGap='24px' templateColumns='repeat(12, 1fr)'>
          <Box gridColumn={{ base: 'span 4', xl: 'span 5' }} justifySelf='flex-end'>
            <DesktopComparisonHeaderItem
              heroImageSrc={desktopHeroImageOfSOV}
              logoImageSrc={desktopLogoImageOfSOV}
              heroImageAlt='SOV數位隱形矯正'
              logoImageAlt='SOV數位隱形矯正'
              type='left'
            />
          </Box>
          <Box gridColumn={{ base: 'span 4', xl: 'span 2' }} alignSelf='center'>
            <Versus>VS</Versus>
          </Box>
          <Box gridColumn={{ base: 'span 4', xl: 'span 5' }} justifySelf='flex-start'>
            <DesktopComparisonHeaderItem
              heroImageSrc={desktopHeroImageOfSmilux}
              logoImageSrc={desktopLogoImageOfSmilux}
              heroImageAlt='Smilux樂齒微矯正'
              logoImageAlt='Smilux樂齒微矯正'
              type='right'
            />
          </Box>
          {comparisonInfoList.map(comparisonInfo => (
            <DesktopComparisonRowItem key={comparisonInfo.label} label={comparisonInfo.label} left={comparisonInfo.sov} right={comparisonInfo.smilux} />
          ))}
        </GridLayout>
      </HeaderRow>
    </Wrapper>
  )
}

export default Desktop
