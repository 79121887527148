import { Grid } from '@chakra-ui/core'
import React from 'react'
import styled from 'styled-components'

import mobileLogoImageOfSmilux from '../../../../../assets/images/mobileLogoSmilux.svg'
import mobileLogoImageOfSOV from '../../../../../assets/images/mobileLogoSOV.svg'
import mobileHeroImageOfSmilux from '../../../../../assets/static/service/mobileHeroSmilux.png'
import mobileHeroImageOfSOV from '../../../../../assets/static/service/mobileHeroSOV.png'
import MobileComparisonRowItem from './MobileComparisonRowItem'
import { ComparisonInfo } from '..'

const Wrapper = styled.div``

const LogoRow = styled.div`
  padding-bottom: 10px;
`

const LogoImage = styled.img``

const HeroRow = styled.div`
  padding-bottom: 14px;
`

const HeroImageWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: fit-content;
`

const HeroImage = styled.img`
  width: 72px;
  height: 72px;
`

const ComparisonRow = styled.div`
  padding: 8px 0;
`

interface MobileProps {
  comparisonInfoList: ComparisonInfo[]
}

const Mobile = (props: MobileProps) => {
  const { comparisonInfoList } = props

  return (
    <Wrapper>
      <LogoRow>
        <Grid templateColumns='repeat(14, 1fr)'>
          <Grid gridColumn='span 4'>
            <div />
          </Grid>
          <Grid gridColumn='span 5' justifySelf='center'>
            <LogoImage src={mobileLogoImageOfSOV} alt='' />
          </Grid>
          <Grid gridColumn='span 5' justifySelf='center' alignSelf='flex-end'>
            <LogoImage src={mobileLogoImageOfSmilux} alt='' />
          </Grid>
        </Grid>
      </LogoRow>
      <HeroRow>
        <Grid templateColumns='repeat(14, 1fr)'>
          <Grid gridColumn='span 4'>
            <div />
          </Grid>
          <Grid gridColumn='span 5' justifySelf='center'>
            <HeroImageWrapper>
              <HeroImage src={mobileHeroImageOfSOV} alt='' />
            </HeroImageWrapper>
          </Grid>
          <Grid gridColumn='span 5' justifySelf='center'>
            <HeroImageWrapper>
              <HeroImage src={mobileHeroImageOfSmilux} alt='' />
            </HeroImageWrapper>
          </Grid>
        </Grid>
      </HeroRow>
      {comparisonInfoList.map(comparisonInfo => (
        <ComparisonRow key={comparisonInfo.label}>
          <MobileComparisonRowItem label={comparisonInfo.label} left={comparisonInfo.sov} right={comparisonInfo.smilux} />
        </ComparisonRow>
      ))}
    </Wrapper>
  )
}

export default Mobile
