import { Box } from '@chakra-ui/core'
import React from 'react'
import styled, { css } from 'styled-components'

const ComparisonLabel = styled.div`
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 32px;
`

const desktopCapsuleCommonStyle = css`
  width: fit-content;
  height: 100%;
  padding: 8px 16px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
`

const DesktopLeftCapsule = styled.div`
  ${desktopCapsuleCommonStyle}

  background-color: rgba(120, 26, 101, 0.5);
  color: white;
  border-radius: 16px 0px 0px 16px;
  text-align: right;
`

const DesktopRightCapsule = styled.div`
  ${desktopCapsuleCommonStyle}

  background-color: rgba(193, 219, 129, 0.5);
  border-radius: 0px 16px 16px 0px;
`

interface DesktopComparisonRowItemProps {
  label: string
  left: string | string[]
  right: string | string[]
}

const DesktopComparisonRowItem = (props: DesktopComparisonRowItemProps) => {
  const { label, left, right } = props

  return (
    <>
      <Box gridColumn={{ base: 'span 4', xl: 'span 5' }} justifySelf='flex-end'>
        {Array.isArray(left) ? (
          <DesktopLeftCapsule>
            <Box justifyItems='flex-end'>
              {left.map((s, index) => (
                <Box key={`desktop-${label}-left-${index}`}>{s}</Box>
              ))}
            </Box>
          </DesktopLeftCapsule>
        ) : (
          <DesktopLeftCapsule>{left}</DesktopLeftCapsule>
        )}
      </Box>
      <Box gridColumn={{ base: 'span 4', xl: 'span 2' }} alignSelf='center' justifySelf='center'>
        <ComparisonLabel>{label}</ComparisonLabel>
      </Box>
      <Box gridColumn={{ base: 'span 4', xl: 'span 5' }} justifySelf='flex-start'>
        {Array.isArray(right) ? (
          <DesktopRightCapsule>
            <Box justifyItems='flex-start'>
              {right.map((s, index) => (
                <Box key={`desktop-${label}-right-${index}`}>{s}</Box>
              ))}
            </Box>
          </DesktopRightCapsule>
        ) : (
          <DesktopRightCapsule>{right}</DesktopRightCapsule>
        )}
      </Box>
    </>
  )
}

export default DesktopComparisonRowItem
