import { Box } from '@chakra-ui/core'
import React from 'react'
import styled, { css } from 'styled-components'

import { GridLayout } from '../../../../../components/Grid'

const ComparisonLabel = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
`

const mobileCapsuleCommonStyle = css`
  height: 100%;
  text-align: left;
  padding: 8px 16px;
  font-weight: 300;
`

const MobileLeftCapsule = styled.div`
  ${mobileCapsuleCommonStyle}

  background-color: rgba(120, 26, 101, 0.5);
  color: white;
  border-radius: 16px 0px 0px 16px;
`

const MobileRightCapsule = styled.div`
  ${mobileCapsuleCommonStyle}

  background-color: rgba(193, 219, 129, 0.5);
  border-radius: 0px 16px 16px 0px;
`

interface MobileComparisonRowItemProps {
  label: string
  left: string | string[]
  right: string | string[]
}

const MobileComparisonRowItem = (props: MobileComparisonRowItemProps) => {
  const { label, left, right } = props

  return (
    <GridLayout templateColumns='repeat(14, 1fr)'>
      <Box gridColumn='span 4' alignSelf='center' justifySelf='center'>
        <ComparisonLabel>{label}</ComparisonLabel>
      </Box>
      <Box gridColumn='span 5'>
        {Array.isArray(left) ? (
          <MobileLeftCapsule>
            <Box>
              {left.map((s, index) => (
                <Box key={`mobile-${label}-left-${index}`}>{s}</Box>
              ))}
            </Box>
          </MobileLeftCapsule>
        ) : (
          <MobileLeftCapsule>{left}</MobileLeftCapsule>
        )}
      </Box>
      <Box gridColumn='span 5'>
        {Array.isArray(right) ? (
          <MobileRightCapsule>
            <Box>
              {right.map((s, index) => (
                <Box key={`mobile-${label}-right-${index}`}>{s}</Box>
              ))}
            </Box>
          </MobileRightCapsule>
        ) : (
          <MobileRightCapsule>{right}</MobileRightCapsule>
        )}
      </Box>
    </GridLayout>
  )
}

export default MobileComparisonRowItem
