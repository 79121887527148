import { Grid } from '@chakra-ui/core'
import React from 'react'
import styled from 'styled-components'

import { viewport } from '../../../../../constants/viewport'

type DesktopComparisonHeaderItemType = 'left' | 'right'

const HeroImageWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: fit-content;
`

const HeroImage = styled.img`
  width: 160px;
  height: 160px;
`

const LogoImageWrapper = styled.div`
  display: flex;
  align-items: center;
`

const LogoImage = styled.img`
  @media (min-width: ${viewport.tablet}px) {
    height: 58px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 45px;
  }
`
interface DesktopComparisonHeaderItemProps {
  heroImageSrc: any
  logoImageSrc: any
  heroImageAlt?: string
  logoImageAlt?: string
  type: DesktopComparisonHeaderItemType
}

const DesktopComparisonHeaderItem = (props: DesktopComparisonHeaderItemProps) => {
  const { heroImageSrc, logoImageSrc, heroImageAlt, logoImageAlt, type } = props

  const justifyItems = type === 'left' ? 'flex-end' : 'flex-start'

  return (
    <Grid rowGap='16px' justifyItems={justifyItems}>
      <Grid>
        <HeroImageWrapper>
          <HeroImage src={heroImageSrc} alt={heroImageAlt} />
        </HeroImageWrapper>
      </Grid>
      <Grid>
        <LogoImageWrapper>
          <LogoImage src={logoImageSrc} alt={logoImageAlt} />
        </LogoImageWrapper>
      </Grid>
    </Grid>
  )
}

export default DesktopComparisonHeaderItem
