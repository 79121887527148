import React from 'react'
import styled from 'styled-components'

import { viewport } from '../../../../constants/viewport'
import Desktop from './Desktop'
import Mobile from './Mobile'

export interface ComparisonInfo {
  label: string
  sov: string | string[]
  smilux: string | string[]
}

const comparisonInfoList: ComparisonInfo[] = [
  {
    label: '品牌知名度',
    sov: 'MIT台灣第一品牌',
    smilux: 'MIT台灣第一品牌'
  },
  {
    label: '牙套材料選用',
    sov: 'SOVORCE 舒服力牙套膜片',
    smilux: 'SOVORCE 舒服力牙套膜片'
  },
  {
    label: '材質安全性',
    sov: '通過多項生物相容性檢測',
    smilux: '通過多項生物相容性檢測'
  },
  {
    label: '牙齒移動技術',
    sov: ['Optimize Attachment', '最佳化SOV附件技術'],
    smilux: ['Optimize Attachment', '最佳化SOV附件技術']
  },
  {
    label: '專業牙醫問診',
    sov: 'Yes',
    smilux: 'Yes，非自助式牙套'
  },
  {
    label: '術前諮詢流程',
    sov: '進行3D掃描建檔，並提供可視化矯正結果預測',
    smilux: '進行3D掃描建檔，並提供可視化矯正結果預測'
  },
  {
    label: '療程複雜度',
    sov: '微調到拔牙治療',
    smilux: '輕微凌亂'
  },
  {
    label: '牙齒調整範圍',
    sov: '前排牙齒美觀到後牙咬合不正',
    smilux: '專注於前排牙齒美觀調整'
  },
  {
    label: '適應症',
    sov: ['骨性問題以外皆適用', '*骨性問題例如：骨性暴牙、骨性戽斗、骨性咬合不正'],
    smilux: '兔寶寶牙、門牙微亂、齒縫稍大、二次矯正'
  },
  {
    label: '平均療程時間',
    sov: '18~36個月',
    smilux: '6~15個月'
  },
  {
    label: '是否需要拔牙',
    sov: '若牙齒排列空間不足，考慮進行IPR(將太大顆的牙齒磨小)、或是拔牙治療',
    smilux: '不需要拔牙，若牙齒排列空間不足，多以IPR(將太大顆的牙齒磨小)獲取空間'
  },
  {
    label: '是否可單排矯正',
    sov: ['Yes', '可以只矯正上排或下排牙齒'],
    smilux: ['Yes', '可以只矯正上排或下排牙齒']
  },
  {
    label: '牙套售後服務',
    sov: ['24hr線上客服', 'SOV Diary app線上生理追蹤系統'],
    smilux: '24hr線上客服'
  },
  {
    label: '療程費用',
    sov: '依照齒列調整狀況評估費用',
    smilux: ['上下排$99,000', '單排$59,000']
  }
]

const MobileWrapper = styled.div`
  @media (min-width: ${viewport.tablet}px) {
    display: none;
  }
`

const DesktopWrapper = styled.div`
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`

const ComparisonOfSmilux = () => {
  return (
    <>
      <MobileWrapper>
        <Mobile comparisonInfoList={comparisonInfoList} />
      </MobileWrapper>
      <DesktopWrapper>
        <Desktop comparisonInfoList={comparisonInfoList} />
      </DesktopWrapper>
    </>
  )
}

export default ComparisonOfSmilux
